import React, { useState, useEffect } from 'react';
import Modal from './Modal';
import Grid from './Grid';
import Dropdown from './DropDown';
import projects from '../data/projects';

const ProjectGallery = () => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const mobiileScreenSize = 768;
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= mobiileScreenSize);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= mobiileScreenSize);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const openModal = (project) => {
    console.log('openModal called for:', project);
    setSelectedProject(project);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProject(null);
  };

  return (
    <div className="grid-container">
      {projects.map((project, index) => (
        <div
          key={index}
          className="project-card"
          onClick={() => openModal(project)}
        >
          <div className="image-wrapper">
            <img
              src={project.banner}
              alt={project.title}
              className="project-image"
            />
            <div className="overlay">
              <h3>{project.title.toUpperCase()}</h3>
              <p>{project.role.toUpperCase()}</p>
            </div>
          </div>
        </div>
      ))}
      <Modal isOpen={isModalOpen} onClose={closeModal} project={selectedProject} />
    </div>
  );
};

export default ProjectGallery;
