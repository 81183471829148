const showreels = [
    {
        video: 'https://www.youtube.com/embed/JJcPWgoY3Jg?si=-x3DZJQYWfArNy_8',
        alt: 'composition showreel'
    },
    {
        video: 'https://www.youtube.com/embed/kVKcglNUEec?si=1vr817qj8cu6r9EO',
        alt: 'composition & implementation showreel'
    },
    {
        video: 'https://www.youtube.com/embed/YQJNsIcWQgI?si=s9shmzNtw8PRJBBH',
        alt: 'sound design showreel'
    },
    {
        video: 'https://www.youtube.com/embed/T-eNkVyxcfM?si=fpCZspvy45cjwRNF',
        alt: 'voice acting showreel'
    }
];

export default showreels;
