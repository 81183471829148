import { React, useState } from 'react';
import './DiscordModal.css';
import { FaDiscord } from "react-icons/fa";
import { IoIosCloseCircle } from 'react-icons/io';
import { IoIosCopy } from 'react-icons/io'

const DiscordModal = ({ isOpen, onClose }) => {
    const discordId = 'mightymuso'
    const [copySuccess, setCopySuccess] = useState('');

    const copyToClipboard = () => {
        navigator.clipboard.writeText(discordId)
            .then(() => {
                setCopySuccess('Copied!');
            })
            .catch(err => {
                console.error('Failed to copy text: ', err);
                setCopySuccess('Failed to copy');
            });
    };

    const handleClose = () => {
        setCopySuccess('');
        onClose();
    };


    if (!isOpen) return null;

    return (
        <div className="discord-modal-overlay" onClick={onClose}>
            <div className="discord-modal-content" onClick={(e) => e.stopPropagation()}>
                <h3>Add me!</h3>
                <div className='discord-link'>
                    <div className='discord-username'>
                        <p>{discordId}</p>
                        <button onClick={copyToClipboard} className='copy-text-button'><IoIosCopy /></button>
                        {copySuccess && <span style={{ marginLeft: '10px', color: 'green' }}>{copySuccess}</span>}
                    </div>
                    <a href="https://discordapp.com/" className='discord-icon' target="_blank" rel="noopener noreferrer"><FaDiscord /></a>
                </div>
                <div className='discord-modal-close-container'>
                    <button onClick={onClose && handleClose} className='discord-modal-close-button'><IoIosCloseCircle /></button>
                </div>
            </div>
        </div>
    );
};

export default DiscordModal;
