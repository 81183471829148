import React, { useState, useEffect } from 'react';
import './Modal.css';
import { IoIosArrowForward } from 'react-icons/io';
import { IoLogoGameControllerB } from 'react-icons/io';
import { IoIosCloseCircle } from 'react-icons/io';

const Modal = ({ isOpen, onClose, project }) => {
  const mobiileScreenSize = 768;
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > mobiileScreenSize);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= mobiileScreenSize);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > mobiileScreenSize);
      setIsSmallScreen(window.innerWidth <= mobiileScreenSize);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!project) return null;


  return (
    <div className="project-overlay" onClick={onClose}>
      {/* Add for future animation implementation
            style={{ transform: isOpen ? 'translateX(0)' : 'translateX(-100%)' }} 
      */}
      <div className="project-content" onClick={(e) => e.stopPropagation()}>
        <img src={project.banner} alt={project.title} className='project-banner' />
        <div className='content-wrapper'>
          <div className='title-link'>
            <h2>{project.title}</h2>
            {project.link ? (
              <a href={project.link} className='play-icon' target="_blank" rel="noopener noreferrer">
                <IoLogoGameControllerB />
              </a>
            ) : (
              <span style={{ cursor: 'not-allowed', opacity: 0.2, fontSize: 50 }}>
                <IoLogoGameControllerB />
              </span>
            )}
          </div>
          <div className='project-details'>
            <div className='release-date'>
              <h3 className='denominator-title'>Release Date:</h3>
              <h3 className='denominator-text'>{project.published}</h3>
            </div>
            <div className='developer'>
              <h3 className='denominator-title'>Developer:</h3>
              <h3 className='denominator-text'>{project.publisher}</h3>
            </div>
            <div className='role'>
              <h3 className='denominator-title'>Role:</h3>
              <h3 className='denominator-text'>{project.role}</h3>
            </div>
          </div>
          <p>{project.description}</p>
          <div className="video-wrapper">
            <iframe
              width="560"
              height="315"
              src={project.video}
              title={project.title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            {/* Add iframe for bandcamp link */}
          </div>
        </div>
        {isSmallScreen && (
          <div className='mobile-project-close-container'>
            <button className="mobile-project-close-button" onClick={onClose}>
              <IoIosCloseCircle />
            </button>
          </div>
        )}
      </div>
      {isLargeScreen && (
        <button className="project-close-button" onClick={onClose}>
          <IoIosArrowForward />
        </button>
      )}
    </div>
  );
};

export default Modal;
