import React, { useState, useEffect, useRef } from 'react';
import './Dropdown.css';
import EmailModal from './EmailModal';
import HamburgerModal from './HamburgerModal';
import DiscordModal from './DiscordModal';
import { IoIosAddCircle } from 'react-icons/io';
import { FaLinkedinIn } from "react-icons/fa";
import { FaDiscord } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { SiBandcamp } from "react-icons/si";
import { SiSoundcloud } from "react-icons/si";

const Dropdown = () => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
    const [isHamburgerModalOpen, setIsHamburgerModalOpen] = useState(false);
    const [isDiscordModalOpen, setIsDiscordModalOpen] = useState(false);

    const mobiileScreenSize = 768;
    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > mobiileScreenSize);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= mobiileScreenSize);

    useEffect(() => {
        const handleResize = () => {
            setIsLargeScreen(window.innerWidth > mobiileScreenSize);
            setIsSmallScreen(window.innerWidth <= mobiileScreenSize);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
            setIsHamburgerModalOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const openEmailModal = () => {
        setIsEmailModalOpen(true);
        setIsOpen(false);
    };

    const closeEmailModal = () => {
        setIsEmailModalOpen(false);
    };

    const openDiscordModal = () => {
        setIsDiscordModalOpen(true);
        setIsOpen(false);
    };

    const closeDiscordModal = () => {
        setIsDiscordModalOpen(false);
    };

    const openHamburgerModal = () => {
        setIsHamburgerModalOpen(true);
        setIsOpen(false);
    };

    const closeHamburgerModal = () => {
        setIsHamburgerModalOpen(false);
    };

    return (
        <div className="dropdown" ref={dropdownRef}>
            <IoIosAddCircle size={50} className="hamburger-menu" onClick={isSmallScreen ? openHamburgerModal : toggleDropdown} />
            {isOpen && isLargeScreen && (
                <ul className="dropdown-menu">
                    <div className='connect'>
                        <div className='connect-header'>
                            <h3>CONNECT</h3>
                        </div>
                        <div className='social-icons'>
                            <li><a href="https://www.linkedin.com/in/mightymuso/" target="_blank" rel="noopener noreferrer"><FaLinkedinIn /></a></li>
                            <li><a href='#' onClick={openEmailModal}><MdEmail /></a></li>
                            <li><a href="#" onClick={openDiscordModal}><FaDiscord /></a></li>
                        </div>
                    </div>
                    <div className='listen'>
                        <div className='listen-header'>
                            <h3>LISTEN</h3>
                        </div>
                        <div className='social-icons'>
                            <li><a href="https://mightymuso.bandcamp.com" target="_blank" rel="noopener noreferrer"><SiBandcamp /></a></li>
                            <li><a href="https://soundcloud.com/mightymuso" target="_blank" rel="noopener noreferrer"><SiSoundcloud /></a></li>
                        </div>
                    </div>
                </ul>
            )}
            <div className='mobile-close-container'>
                <HamburgerModal isOpen={isHamburgerModalOpen} onClose={closeHamburgerModal} />
            </div>
            <EmailModal isOpen={isEmailModalOpen} onClose={closeEmailModal} />
            <DiscordModal isOpen={isDiscordModalOpen} onClose={closeDiscordModal} />
        </div>

    );
};

export default Dropdown;
