import { React, useState } from 'react';
import './HamburgerModal.css';
import EmailModal from './EmailModal';
import DiscordModal from './DiscordModal';
import { FaLinkedinIn, FaDiscord } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { SiBandcamp, SiSoundcloud } from "react-icons/si";
import { IoIosArrowDown } from 'react-icons/io';
import { IoIosCloseCircle } from 'react-icons/io';

const HamburgerModal = ({ isOpen, onClose }) => {
    const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
    const [isDiscordModalOpen, setIsDiscordModalOpen] = useState(false);

    if (!isOpen) return null;

    // const toggleDropdown = () => {
    //     setIsOpen(!isOpen);
    // };

    const openEmailModal = () => {
        setIsEmailModalOpen(true);
    };

    const closeEmailModal = () => {
        setIsEmailModalOpen(false);
    };

    const openDiscordModal = () => {
        setIsDiscordModalOpen(true);
    };

    const closeDiscordModal = () => {
        setIsDiscordModalOpen(false);
    };

    return (
        <div className="hamburger-overlay" onClick={onClose}>
            <div className="hamburger-content" onClick={(e) => e.stopPropagation()}>
                <div className='connect'>
                    <div className='connect-header'>
                        <h3>CONNECT</h3>
                    </div>
                    <div className='social-icons'>
                        <li><a href="https://www.linkedin.com/in/mightymuso/" target="_blank" rel="noopener noreferrer"><FaLinkedinIn /></a></li>
                        <li><a href='#' onClick={openEmailModal}><MdEmail /></a></li>
                        <li><a href="#" onClick={openDiscordModal}><FaDiscord /></a></li>
                    </div>
                </div>
                <div className='listen'>
                    <div className='listen-header'>
                        <h3>LISTEN</h3>
                    </div>
                    <div className='social-icons'>
                        <li><a href="https://mightymuso.bandcamp.com" target="_blank" rel="noopener noreferrer"><SiBandcamp /></a></li>
                        <li><a href="https://soundcloud.com/mightymuso" target="_blank" rel="noopener noreferrer"><SiSoundcloud /></a></li>
                    </div>
                </div>
            </div>
            <div className='mobile-project-close-container'>
                <button className="mobile-project-close-button" onClick={onClose}>
                    <IoIosCloseCircle />
                </button>
            </div>
            <EmailModal isOpen={isEmailModalOpen} onClose={closeEmailModal} />
            <DiscordModal isOpen={isDiscordModalOpen} onClose={closeDiscordModal} />
        </div>
    );
};

export default HamburgerModal;
