const projects = [
    {
        title: 'span',
        publisher: 'space base place',
        role: 'composer // implementation',
        published: 'TBA',
        description: 'Explore, create, and defend humanity from the machine onslaught in this fast-paced survival adventure. Navigate an ancient space megastructure, master a powerful tether system for traversal and combat, and use a detailed building system to construct epic bases. Play solo or with friends in co-op multiplayer, enduring hazardous environments and relentless AI-controlled sieges as you race to uncover the mysteries of the SPAN.',
        banner: `${process.env.PUBLIC_URL}/images/banners/span-banner.jpg`,
        video: 'https://www.youtube.com/embed/QumUPzD-HTU?si=r7BFcNSjX8RP4l37',
        link: ''
        // TODO: Add bandcamp links
    },
    {
        title: 'power sink',
        publisher: 'winterwire games',
        published: 'Q1 2025',
        role: 'composer // sound designer // implementation',
        description: 'In the depths of a decaying underwater power plant, you must guide the Diver down in order to restore power to their home and uncover the secrets held by the creaky grid that supports it.',
        banner: `${process.env.PUBLIC_URL}/images/banners/power-sink-banner.png`,
        video: 'https://www.youtube.com/embed/wtMIyUy98js?si=5L2eOIRwHi-xTXYN',
        link: 'https://store.steampowered.com/app/2289740/Power_Sink/'
    },
    {
        title: 'fabulous crisis',
        publisher: 'aramis marcuola-bel',
        published: 'tba',
        role: 'composer',
        description: 'A VR rhythm game currently in early production',
        banner: `${process.env.PUBLIC_URL}/images/banners/fc-banner.png`,
        video: '',
        link: ''
    },
    {
        title: 'project break//down',
        publisher: 'fist face studios',
        published: 'tba',
        role: 'composer',
        description: 'Project: Break//Down is a experimental 3D action-adventure brawler with a unique limb-based combat system that allows for satisfying combat. In this game you explore the EZON complex a mass production and distribution facility secretly gone rogue in the shoes of 2 fools.',
        banner: `${process.env.PUBLIC_URL}/images/banners/pbd-banner.jpg`,
        video: '',
        link: 'https://store.steampowered.com/app/2317220/Project_BreakDown/'
    },
    {
        title: 'misc. a tiny tale',
        publisher: 'tinyware games',
        role: 'voice actor',
        published: 'TBA',
        description: 'Embark on an adventure as a tiny robot made of miscellaneous parts. Collect trash, coins, cogs, and more as you help friends around you. Make the world a better place, one tiny task at a time.',
        banner: `${process.env.PUBLIC_URL}/images/banners/misc-banner.jpg`,
        video: 'https://www.youtube.com/embed/8_yVRukOfoc?si=DmHacCvnCAll98ZD',
        link: 'https://store.steampowered.com/app/1308940/Misc_A_Tiny_Tale/'
    },
    {
        title: 'night of the living ooze',
        publisher: 'winterwire games',
        role: 'composer // sound desiger // voice actor // implementation',
        published: '21.08.2024',
        description: 'You play as a living ooze that hungers for all, flings chunks of itself at enemies and thirsts for the size to consume the entire universe.',
        banner: `${process.env.PUBLIC_URL}/images/banners/notlo-banner.png`,
        video: 'https://www.youtube.com/embed/hBZbkPj30Uo?si=JO_S_Bj8H0CgXwLU',
        link: 'https://winterwiregames.itch.io/night-of-the-living-ooze'
    },
    {
        title: 'surely you jest?!',
        publisher: 'winterwire games',
        role: 'composer // sound desiger // voice actor // implementation',
        published: '28.01.2024',
        description: "Surely you Jest?! is a Physics-Based extravaganza of gags, pranks and more as you, The King's court Jester are commanded to make the King and his hall full of guests laugh till their sides hurt. Can you make enough of a fool out of yourself and the right guests to get people going, or will you show your lack of comedy chops and prove to the King you can't tell a joke to save yourself... literally.",
        banner: `${process.env.PUBLIC_URL}/images/banners/syj-banner.png`,
        video: 'https://www.youtube.com/embed/rhylxlBMGeE?si=o6c8m8thiDD1ZgC_',
        link: 'https://winterwiregames.itch.io/surely-you-jest'
    },
    {
        title: 'a balanced breakfast',
        publisher: 'winterwire games',
        role: 'composer // sound desiger // voice actor // implementation',
        published: '10.07.2023',
        description: "Help your dear old grannie make some breakfast in this Role-Reversed Puzzle Game filled with stylish pixel art, bopping chip-tune beats, and just the right amount of challenge!",
        banner: `${process.env.PUBLIC_URL}/images/banners/aba-banner.png`,
        video: '',
        link: 'https://winterwiregames.itch.io/a-balanced-breakfast'
    },
    {
        title: 'jimmy blitz and the rocket dice',
        publisher: 'team dovecoat',
        role: 'composer',
        published: '18.07.2022',
        description: "We’re pullin’ the biggest casino heist in history, boss. Let’s dominate some games and take home the money with your ROCKET-POWERED DICE! BOOST and FOCUS your way through ten casino puzzles, but make sure the casino patrons don’t notice your antics or you’ll get thrown in the slammer!",
        banner: `${process.env.PUBLIC_URL}/images/banners/jbrd-banner.png`,
        video: 'https://www.youtube.com/embed/cDXtcY1boXo?si=1_Db3AkJjevwRbAQ',
        link: 'https://thegoodowl.itch.io/rocketdice'
    },
    {
        title: 'nest quest',
        publisher: 'team dovecoat',
        role: 'composer',
        published: '25.07.2021',
        description: "Time to get crackin'! Roll, bounce, and fling yourself back to your nest in over 15 egg-citing levels! Collect feathers to rack up your score, or scramble to the exit to get the best time possible. Find the elusive acorn in every level, and you may just unlock that world's Acorn Level for an extra challenge. Will you make it back your cozy nest? Or will you GET YOLKED?!",
        banner: `${process.env.PUBLIC_URL}/images/banners/nestquest-banner.png`,
        video: '',
        link: 'https://www.newgrounds.com/portal/view/807185'
    },
];

export default projects;
