import React from 'react';
import "./ProjectShowreels.css";
import showreels from '../data/showreels';

const ProjectShowreels = () => {
  const showreel = showreels.map(({ video, alt }, index) => (
    <div className='video-wrapper' key={index}>
      <iframe
        src={video}
        title={alt}
        frameBorder={0}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  )
  )

  return (
    <div className="showreels-container">
      {showreel}
    </div>
  );
};

export default ProjectShowreels;
