import { React, useEffect, useState } from 'react';
import './Header.css';
import Dropdown from './DropDown';

function Header() {
  const mobiileScreenSize = 768;
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > mobiileScreenSize);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > mobiileScreenSize);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <header className="header">
      <div className='site-profile-logo'>
        <img src={`${process.env.PUBLIC_URL}/images/profile/muso-profile.png`} alt='muso-profile' className='profile' />
        <img src={`${process.env.PUBLIC_URL}/images/profile/muso-profile-logo.png`} alt='muso-logo' className='logo' />
        {/* <h1 className='header-site-name'>MIGHTY MUSO</h1>
        <h2 className='header-roles'>COMPOSER | SOUND DESIGNER | VOICE ACTOR</h2> */}
      </div>
      {isLargeScreen && (
        <div className='header-dropdown'>
          <Dropdown />
        </div>
      )}
    </header>
  );
}

export default Header;
