import React, { useState, useEffect } from 'react';
import './Footer.css';
import { IoIosAddCircle } from 'react-icons/io';
import Dropdown from './DropDown';

function Footer() {
  const mobileScreenSize = 768;
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= mobileScreenSize);


  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= mobileScreenSize);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <footer className="footer">
      {/* <p>&copy; 2024 MIGHTY MUSO</p> */}
      {isSmallScreen && (
        <Dropdown className='hamburger-menu-icon' />
      )}
    </footer>
  );
}

// TODO: Position copyright onto far left for smaller screens

export default Footer;
